import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import SearchEngine from '../engine/SearchEngine'

export default class Cookiesky extends Component {
    /**
     * Tato metoda zapisuje poslednych 10 hodnot id poloziek, ktore boli otvorene
     * @param {*} mozemZapisat  toto je hodnota z konfigu, funkcia je static tak je treba ju sem dostat parametrom
     * @param {*} idPolozky     toto je id polozky, ktore sa dopise do cookies
     */
    static zapisCookies(mozemZapisat, idPolozky) {
        if (mozemZapisat) {
            //zadefinujem cookie sluzby ako konstantu
            const cookies = new Cookies();
            let uzZapisaneCookies = cookies.get('fsSearcher');

            // ak uz je nieco zapisane v cookies
            if (uzZapisaneCookies != undefined) {
                uzZapisaneCookies = idPolozky + ',' + uzZapisaneCookies;
                uzZapisaneCookies = uzZapisaneCookies.split(",");
                if(uzZapisaneCookies.length > 10) {
                    uzZapisaneCookies = uzZapisaneCookies.slice(uzZapisaneCookies.length - 10, uzZapisaneCookies.length);
                }
                
                uzZapisaneCookies = uzZapisaneCookies.filter(function(item, pos, self) {
                    return self.indexOf(item) == pos;
                })
                uzZapisaneCookies = uzZapisaneCookies.toString();
            
            // ak este nic nieje v cookies
            } else {
                uzZapisaneCookies = idPolozky;
            }

            cookies.set('fsSearcher', uzZapisaneCookies, { path: '/' });
        }
    }

    
    /**
     * Tato metoda sa nacita pri spusteni aplikacie a nacita cookies hodnoty
     */
    static nacitajCookies(state) {
        const cookies = new Cookies();
        let polozkyZCookies = cookies.get('fsSearcher');
        if (polozkyZCookies != undefined) {
            polozkyZCookies = polozkyZCookies.split(",");
            console.log(polozkyZCookies)
            console.log(SearchEngine.najdiPolozkyPodlaId(state, polozkyZCookies))
            state.cookiesitems = SearchEngine.najdiPolozkyPodlaId(state, polozkyZCookies)
        }
        return state;
    }
}
