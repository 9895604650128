import React, { Component } from 'react'

export default class Mapping extends Component {

    /**
     * tato metoda je volana zo smart komponentu
     * zavola obe mapovacie metody (namapuje typ aj skupinu)
     * @param {*} state 
     */
    static namapujNajlepsiePolozky(state) {
        state = this.namapujTypy(state,'bestitems');
        state = this.namapujSkupinu(state,'bestitems');

        return state.bestitems;
    }

    /**
     * tato metoda je volana zo smart komponentu
     * zavola obe mapovacie metody (namapuje typ aj skupinu)
     * @param {*} state 
     */
    static namapujPolozkyZCookies(state) {
        console.log(state)
        state = this.namapujTypy(state,'cookiesitems');
        state = this.namapujSkupinu(state,'cookiesitems');

        return state.cookiesitems
    }

    /**
     * Tato metoda namapuje typy najlepsich poloziek podla nacitaneho konfigu
     * @param {*} state 
     */
    static namapujTypy(state, coMapujem) {
        var typTickeru;
        for (let index = 0; index < state[coMapujem].length; index++) {
            typTickeru = state[coMapujem][index].idTypu;
            for (let indexTypu = 0; indexTypu < state.items.typy.length; indexTypu++) {
                if (typTickeru == state.items.typy[indexTypu].idTypu) {
                    state[coMapujem][index] = {'idPolozky': state[coMapujem][index].idPolozky, 
                    'idSkupiny': state[coMapujem][index].idSkupiny, 
                    'idTypu': state.items.typy[indexTypu].nazov, 
                    'farbaTypu': state.items.typy[indexTypu].farbaHex, 
                    'farbaPismaTypu': state.items.typy[indexTypu].pismoHex, 
                    'kod': state[coMapujem][index].kod, 
                    'nazov': state[coMapujem][index].nazov, 
                    'urlObrazku': state[coMapujem][index].urlObrazku, 
                    'vysledneUrl': state[coMapujem][index].vysledneUrl}
                }
            }
        }
        return state;
    }

    /**
     * Tato metoda namapuje skupiny najlepsich poloziek podla nacitaneho konfigu
     * @param {*} state 
     */
    static namapujSkupinu(state, coMapujem) {
        var skupinaTickeru;

        for (let index = 0; index < state[coMapujem].length; index++) {
            skupinaTickeru = state[coMapujem][index].idSkupiny;
            for (let indexTypu = 0; indexTypu < state.items.skupiny.length; indexTypu++) {
                if (skupinaTickeru == state.items.skupiny[indexTypu].idSkupiny) {
                    state[coMapujem][index] = {'idPolozky': state[coMapujem][index].idPolozky, 
                    'idSkupiny': state.items.skupiny[indexTypu].nazov, 
                    'farbaSkupiny': state.items.skupiny[indexTypu].farbaHex,
                    'farbaPismaSkupiny': state.items.skupiny[indexTypu].pismoHex,
                    'idTypu': state[coMapujem][index].idTypu, 
                    'farbaTypu': state[coMapujem][index].farbaTypu, 
                    'kod': state[coMapujem][index].kod, 
                    'nazov': state[coMapujem][index].nazov, 
                    'urlObrazku': state[coMapujem][index].urlObrazku, 
                    'vysledneUrl': state[coMapujem][index].vysledneUrl}
                }
            }
        }

        return state;
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}
