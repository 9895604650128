import logo from './logo.svg';
import './App.css';
import SearcherSmart from './components/SearcherSmart';

function App() {
  return (
    <div className="lit-searcher">
      <SearcherSmart></SearcherSmart>
    </div>
  );
}

export default App;
