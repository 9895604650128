import React, { Component } from 'react'

export default class SearchEngine extends Component {

    /**
    * tato metoda prida vsetky relevantne tickery podla vyhladavaneho slova
    */
    static pridanieNajlepsichTickerov(state) {
       let suggestions = [];

       if (state.searchWord.length > 0) {
        suggestions = this.hladaniePodlaPrvehoPismena(state);
       }
       console.log("suggestion legngth: ",suggestions)

       // ak je menej ako 10 tak sa pridaju menej relevantne
       if ( (suggestions!=null) && (suggestions.length < (state.items.konfig[0].pocetZobrazovanychPoloziek - state.cookiesitems.length)) ){
        let suggestions2 = [];
        
        suggestions2 = this.hladaniePodlaCelehoNazvu(state, suggestions);
        suggestions = suggestions.concat(suggestions2)
       }

       
       
       state.bestitems = suggestions;

       return state;
    }

    /**
     * Hladanie v polozkach podla prveho slova/suslovia
     * @param {object} state 
     */
    static hladaniePodlaPrvehoPismena(state) {
        let suggestions = [];
        const regex = new RegExp(`^${state.searchWord}`, 'i');
        
        for (let index = 0; index < state.items.polozky.length; index++) {
            
            if(regex.test(state.items.polozky[index].kod) || regex.test(state.items.polozky[index].nazov) || regex.test(state.items.polozky[index].kodBezLomitka)) {
             suggestions.push(state.items.polozky[index])
             if (suggestions.length >= (state.items.konfig[0].pocetZobrazovanychPoloziek - state.cookiesitems.length)) {
                 break;
             }
            }
        }

        return suggestions;
    }

    /**
     * Hladanie v polozkach podla hladaneho slova v celom nazve alebo tickery
     * @param {object} state 
     * @param {array} suggestions 
     */
    static hladaniePodlaCelehoNazvu(state, suggestions) {
        // console.log("state: ", state)

        let suggestions2 = [];
        const regex2 = new RegExp(`${state.searchWord}`, 'i');

        for (let index = 0; index < state.items.polozky.length; index++) {
            if(regex2.test(state.items.polozky[index].kod) || regex2.test(state.items.polozky[index].nazov) || regex2.test(state.items.polozky[index].hladane)) {
                suggestions2.push(state.items.polozky[index])
             if (suggestions2.length >= (state.items.konfig[0].pocetZobrazovanychPoloziek - suggestions.length - state.cookiesitems.length)) {
                 break;
             }
            }
        }

        suggestions2 = suggestions2.filter(function(item, pos) {
            return suggestions.indexOf(item) != pos;
        })

        if (suggestions2.length >= ((state.items.konfig[0].pocetZobrazovanychPoloziek) - suggestions.length)){
            suggestions2 = suggestions2.slice(0,(state.items.konfig[0].pocetZobrazovanychPoloziek - suggestions.length));
        } else {
            // pokial je mensia
            suggestions2 = suggestions2.concat(this.hladaniePodlaNazvuRozsekanehoNaSlova(state, suggestions2));
        }

        return suggestions2;
    }

    /**
     * Toto je volitelna funkcia, ktora robi to, ze hlada v aspon jednom slove... no mozu takto vzniknut duplikaty...
     * @param {} state 
     * @param {*} suggestions 
     * @returns 
     */
    static hladaniePodlaNazvuRozsekanehoNaSlova(state, suggestions) {
        // console.log("state: ", state)

        let suggestions3 = [];
        var slova = state.searchWord.split(" ");

        slova.forEach(slovo => {
            const regex3 = new RegExp(`${slovo}`, 'i');

            for (let index = 0; index < state.items.polozky.length; index++) {
                if(regex3.test(state.items.polozky[index].kod) || regex3.test(state.items.polozky[index].nazov) || regex3.test(state.items.polozky[index].hladane)) {
                    if (suggestions.indexOf(state.items.polozky[index]) === -1) {
                        suggestions3.push(state.items.polozky[index])
                    }
                 if (suggestions3.length >= (state.items.konfig[0].pocetZobrazovanychPoloziek - suggestions.length - state.cookiesitems.length)) {
                     break;
                 }
                }
            }
    
            suggestions3 = suggestions3.filter(function(item, pos) {
                return suggestions.indexOf(item) != pos;
            })
    
            if (suggestions3.length >= ((state.items.konfig[0].pocetZobrazovanychPoloziek) - suggestions.length)){
                suggestions3 = suggestions3.slice(0,(state.items.konfig[0].pocetZobrazovanychPoloziek - suggestions.length));
            }             
        });


        return suggestions3;
    }

    /**
     * tato metoda nacita predvolene polozky z konfigu
     * @param {object} state 
     */
    static nastavDefaultneTickery(state) {
        let suggestions = [];
        let jeVCookies = false;
        console.log("dlzka: ",suggestions.length)
        //Prvy for sa vykona pre vsetky predvolene id poloziek
        for (let index = 0; index < state.items.konfig[0].zoznamPredvolenychIdPoloziek.length; index++) {
            //Druhy for sa vykona v pripade vsetkych predvolenych polozkach pre kazdu jednu nacitanu polozku
            for (let indexPolozky = 0; indexPolozky < state.items.polozky.length; indexPolozky++) {
                if (state.items.polozky[indexPolozky].idPolozky == state.items.konfig[0].zoznamPredvolenychIdPoloziek[index]) {
                    if(state.cookiesitems.length > 0) {
                        jeVCookies = false
                        for (let indexCookies = 0; indexCookies < state.cookiesitems.length; indexCookies++) {
                            if(state.cookiesitems[indexCookies].idPolozky == state.items.polozky[indexPolozky].idPolozky) {
                                jeVCookies = true;
                                break
                            }
                        }
                        if (!jeVCookies) {
                            suggestions.push(state.items.polozky[indexPolozky])
                            break;
                        }
                    } else {
                        suggestions.push(state.items.polozky[indexPolozky])
                        break;
                    }
                }
            }
            //nemoze presiahnut vacsi pocet ako je povolene aj s cookieskami
            if (suggestions.length >= (state.items.konfig[0].pocetZobrazovanychPoloziek - state.cookiesitems.length)) {
                break;
            }
        }
        state.bestitems = suggestions;

        return state;
    }

    /**
     * Tato metoda vrati cele polozky podla IDciek
     * @param {object} state 
     * @param {array} idPoloziek 
     */
    static najdiPolozkyPodlaId(state, idPoloziek) {
        let najdenePolozky = [];
        
        for (let indexHladanehoID = 0; indexHladanehoID < idPoloziek.length; indexHladanehoID++) {
            for (let indexPolozky = 0; indexPolozky < state.items.polozky.length; indexPolozky++) {
                if(state.items.polozky[indexPolozky].idPolozky == Number(idPoloziek[indexHladanehoID])) {
                    najdenePolozky.push(state.items.polozky[indexPolozky])
                    break;
                }
            }
        }

        state.cookiesitems = najdenePolozky

        return state.cookiesitems
    }
}
